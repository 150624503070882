import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { complaintImg, orderPortalImg, mdmLogo } from '../helpers/imageURL';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
//import AxiosApi from 'AxiosApi';
import Iframe from 'react-iframe';
import '../assets/styles/App.css';
import returnPackIcon from '../assets/images/return-box.png';
import oneOrderChangeIcon from '../assets/images/oneOrderChange.png';
import AxiosApi from '../Api';
import {roles,updateRoles} from '../Constants'

import { Card, Image, Text, Badge, Button, Group } from '@mantine/core';
// import './mdm-ticketing/styles.css';
import './portals.css';
import '@mantine/core/styles.css';

const Portals = () => {
  const navigate = useNavigate();
  const [roleAssigned, setRoleAssigned] = useState();
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const { accounts, instance } = useMsal();

  const [oocRole, setOOCRole] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  
  return (
    <div className="container" >

      <div className="row">

        {/* 
{
  roleAssigned !== 'External_Customer' && roleAssigned !== 'Internal_Customer' ?
    <div className="col-md-4 portals_card_parent m-4">
      <div className="portals_card rounded-md">
        <img
          src={mdmLogo}
          className='portal_img'
          alt="mdm Logo"
        />

        <Link to="/mdm">
          <Button variant="filled" color="yellow" className="text-black text-capitalize"
            disabled={(roles.role) == 'External_Customer' || (roles.role) == 'Internal_Customer'}
          >
            Int. Log. Master Data and System support
          </Button>
        </Link>
      </div>
    </div>
    : null
}
*/}


        {/* 2rd card */}
        {roleAssigned !== 'CX_Agent' && roleAssigned !== 'Employee' && roleAssigned !== 'Employee' ?
          <div className="col-md-4 portals_card_parent m-4">
            <div className="portals_card rounded-md">
              <img
                src={complaintImg}
                className="portal_img"
                alt="..."
              />

              <Link to="/cq-portal-home">
                <Button variant="filled" color="yellow" className="text-black text-capitalize">
                  Customer Complaints Portal
                </Button>
              </Link>
            </div>
          </div>
          : null}



        {/* 3rd card */}
        <div className="col-md-4 portals_card_parent m-4">
          <div className="portals_card rounded-md ">
            <img
              src={oneOrderChangeIcon}
              className="portal_img"
              alt="..."
            />
            <Link to="/one-order-change-homepage">
              <Button disabled={isLoading}
                variant="filled" color={isLoading === false ? "yellow" : "gray"} className="text-black text-capitalize">
                {isLoading === false ? "One Order Change Portal" : "Please Wait..."}
              </Button>
            </Link>
          </div>
        </div>
        {/* 4th card */}
        <div className="col-md-4 portals_card_parent m-4">
          <div className="portals_card rounded-md ">
            <img
              src={returnPackIcon}
              className="portal_img"
              altt='Ret Pack Logo'
            />

            <Link to="/retPack">
              <Button variant="filled" color="yellow" className="text-black text-capitalize">
                Ret Pack Portal
              </Button>
            </Link>
          </div>
        </div>

      </div>

     
    </div>
  );
};

export default Portals;
