// api.js

import axios from 'axios';
import { roles } from './Constants';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT_URL_PREFIX, // Your API base URL
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // Add your custom headers here
    const token = localStorage.getItem("Authorization")// You might store a token in localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // You can add more headers or modify the existing ones as needed
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
