import React, { useState, useEffect,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from "jwt-decode";


import ABILogo from '../assets/images/abi_logo.png';
import INT_Logo from '../assets/images/Dark_Backgrounds.png';
import { loginRequest } from '../authConfig';
import  {roles,updateRoles}from '../Constants'
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { Button, Badge } from '@mantine/core';
import '../assets/styles/Login.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from '../authcontext';
import AxiosApi from "../Api.js";
function useFormInputs(initialInput) {
  const [value, setValue] = useState(initialInput);
  function handleChange(e) {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange,
  };
}
const Login = (props) => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { instance, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const [error, setError] = useState('');
  const [iserror, setisError] = useState(false);
  const [emailError, setEmailError] = useState();
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log(`AUTH LOG = ${isAuthenticated}`);
    if (isAuthenticated && InteractionStatus.None) navigate('/');
  }, [inProgress]);
  var roleAssigned;

  useEffect(() => {
    if (roles.username)
      props.loggedInViaInputFn(true);
  }, [])
  // console.log(`AUTH LOG = ${isAuthenticated}`);
  // console.log('change', props.loggedInViaInput);

  const onSubmit = async () => {
    const isValid = validationcheck();
    if (isValid) {
      try {
        const saltResponse = await axios.get(
          `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchSaltKey?email=${props.username}`
        );
        const saltKey = saltResponse.data.recordset[0]?.saltKey;
  
        const authResponse = await axios.get(
          `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/checkRecord?email=${props.username}&password=${props.password}&saltKey=${saltKey}`
        );
  
        if (authResponse?.data?.user[0] === 0) {
          setisError(true);
          setError('Username / Password not correct');
          return;
        }
  
        const token = authResponse?.data?.token;
        localStorage.setItem("Authorization", token);
        const decoded = jwtDecode(token);
        localStorage.setItem("email", decoded.email);
        
        updateRoles({ Authorization: token });
        AxiosApi.post(
          `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getallrole`,
          { email: decoded.email }
        ).then((res) => {
          console.log(res);
          updateRoles({ role: res.data?.cq });
          updateRoles({ ooc_user_role: res.data?.ooc });
          updateRoles({ userRole: res.data?.retpak });
          const payload = {
            username: decoded.email,
            cq:res.data?.cq , // Replace with actual data from API
            ooc:res.data?.ooc,
            retpack: res.data?.retpak,
            isAuthenticate:true,
            email:decoded.email
          };
          login(payload)
  
          
        });
       
      
  
        
  
        props.loggedInViaInputFn(true);
        navigate('/');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Enter valid username or password!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          console.error('Error during authentication:', error);
        }
      }
    }
  };
  
  // console.log(props.loggedInViaInputFn,'login check')
  const validationcheck = () => {
    let username_error = '';
    let password_error = '';

    if (!props.username) {
      username_error = 'Please enter username';

      setError(username_error);
      setisError(true);
    }

    if (!props.password) {
      password_error = 'Please enter password';

      if (error === '') {
        setError(password_error);
        setisError(true);
      }
    }

    if (username_error || password_error) {
      return false;
    } else {
      return true;
    }
  };
  if (isAuthenticated || props.loggedInViaInput) {
    return <Navigate to={{ pathname: '/' }} />;
  }

  const isValidEmail = (value) => {
    var validRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.match(validRegex)) setEmailError('Valid Email');
    else setEmailError('Invalid Email');
  };
  return (
    !isAuthenticated &&
    !props.loggedInViaInput &&
    !(roles.username?.length) > 0 && (
      <>
        <div className="container-fluid m-0 p-0" style={{ overflow: "hidden" }}>
          <div className="" style={{ width: "100%", overflow: "hidden", background: "black" }}>
            <img src={ABILogo} className="h-6" alt="AB InBev Logo" style={{ paddingRight: '30px' }} />
            <img
              src={INT_Logo}
              className="h-8"
              alt="ACOE Logo"
              style={{
                borderLeft: '1px solid #c9c9c9',
                paddingLeft: '60px',
              }}
            />
          </div>
        </div>
        {/* navbar ends here  */}
        <div id="login_container">

          <div className="container my-4" >


            <div className="row d-flex justify-content-around align-items-center">

              <div className="col-md-4 login_card rounded-md">

                <h3 className="text-center mb-6">Customer Login</h3>

                <div className="card-title text-center">
                  {iserror && (
                    <div className="alert alert-warning" role="alert">
                      {error}
                    </div>
                  )}
                  <div className="my-4">

                    <div className="d-flex justify-content-between align-items-center">
                      <p className='p-0 m-0'>User Name</p>
                      <p className='p-0 mb-1'>
                        {emailError === 'Invalid Email' &&
                          <Badge color="red">{emailError}</Badge>
                        }
                        {emailError === 'Valid Email' &&
                          <Badge color="green">{emailError}</Badge>
                        }
                      </p>
                    </div>

                    <input
                      type="text"
                      onChange={(e) => {
                        props.setusername(e.target.value);
                        isValidEmail(e.target.value);
                      }}
                      className="form-control"

                    />
                  </div>
                  <div className="my-4">
                    <p className='text-left m-0 p-0'>Password</p>
                    <input
                      type="password"
                      className="form-control"

                      onChange={(e) => {
                        props.setpassword(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <Button variant="filled" color="yellow" fullWidth
                  disabled={emailError == 'Invalid Email'}
                  onClick={() => onSubmit()}
                >
                  Login
                </Button>
                <ToastContainer />
                <a href="https://international-logistics.ab-inbev.com/forgotPassword">
                  Forgot Password? Click Here
                </a>

              </div>


              <div className="col-md-1">
                <h3>OR</h3>
              </div>

              <div className="col-md-4 login_card rounded-md" >

                <h3 className="text-center">ABInBev Employee SSO Login</h3>

                <p style={{ fontSize: '16px' }} className='text-center'>
                  Please use your <strong>AB InBev ID</strong> to login
                </p>

                <Button variant="filled" color="yellow" fullWidth
                  onClick={() =>
                    instance.loginRedirect(loginRequest).catch((e) => console.log(e))
                  }
                >
                  Login using SSO
                </Button>

              </div>
            </div>


          </div>
        </div>
      </>
    )
  );
};

export default Login;
