import React, { useState, useEffect } from "react";
import ABILogo from "../../../assets/images/abi_logo.png";
import INT_Logo from '../../../assets/images/Dark_Backgrounds.png';
import CommonNavbar from "../../../common/Navbar/CommonNavbar";
import { roles } from "../../../Constants";

const Header = () => {
  return (
    <div className='d-flex justify-content-between align-items-center px-5 py-2' style={{ backgroundColor: "black" }}>

      <CommonNavbar
        images={[ABILogo, INT_Logo]}
        // avatar={profilePhoto ? URL.createObjectURL(profilePhoto) : defaultProfilePhoto}
        appVersion={process.env.REACT_APP_VERSION || "BETA 4.1"}
        username={(roles.name)?.length > 0 ? (roles.name) : "Hi, Guest"}
        useremail={(roles.username)?.length > 0 ? (roles.username) : null}
      // support="mailto:DL-COEAutomationRM@AnheuserBuschInBev.onmicrosoft.com"
      />

    </div>
  );
};

const style = {
  image: {
    marginTop: 10,
    height: "60%",
    width: 150,
  },
};
export default Header;
