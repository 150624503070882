// navbar1 Homepage
import React, { useEffect, useState ,useContext} from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import dataService from '../services/dataService';
import defaultProfilePhoto from '../assets/images/profile.jpg';
import { loginRequest, snowBackendRequest } from '../utils/authConfig';
import FeatherIcon from 'feather-icons-react';
import INT_Logo from '../assets/images/Dark_Backgrounds.png';
import axios from 'axios';
import { AiFillFileAdd } from 'react-icons/ai';
import { Navigate, Link } from 'react-router-dom';
import Logo from '../assets/images/abi_logo.png';
import {roles,updateRoles} from '../Constants';
import AxiosApi from '../Api';

import { Menu, Button, Text, rem } from '@mantine/core';
import {
  IconInfoCircle, IconClick, IconDownload, IconSettings,
  IconSearch,
  IconPhoto,
  IconMessageCircle,
  IconTrash,
  IconArrowsLeftRight,
  IconLogout,
  IconHelp,
  IconUserPlus,
  IconSetting,
  IconHome
} from '@tabler/icons-react';
import CommonNavbar from '../common/Navbar/CommonNavbar';
import '@mantine/core/styles.css';
import CommonHoverCard from '../common/Navbar/CommonHoverCard';
import { AuthProvider ,AuthContext} from "../authcontext.js"; 
const NavBar = () => {
  const isAuthenticated = useIsAuthenticated();
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const { authState, login, logout } = useContext(AuthContext);
  const { accounts, instance } = useMsal();
  const handleLogout = () => {
    instance.logout({
      postLogoutNavigateUri: '/',
    });
    localStorage.removeItem("username");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    <Navigate to={{ pathname: "/" }} />
  };
  // const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);

  const [roleAssigned, setRoleAssigned] = useState();
  const togglingDropDown = () => { setOpened(!opened) };
  useEffect(() => {
    if (accounts.length > 0) {
       console.log('starting below');
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          // console.log(response);
          //test
          updateRoles({Authorization: response.idToken})
          localStorage.setItem("email", accounts[0].username);
          console.log("hi 78829892")
          console.log(roles)
          localStorage.setItem('Authorization', response.idToken)
          dataService
            .getMSGraphPhoto(response.accessToken)
            .then((image) => {
              if (image.type === 'image/jpeg') setProfilePhoto(image);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
        });
      instance
        .acquireTokenSilent({
          ...snowBackendRequest,
          account: accounts[0],
        })
        .then((response) => {
          // console.log(response)
          updateRoles({snowtoken: response.accessToken})
          localStorage.setItem('snowtoken', response.accessToken)
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
        });
    }
    const email = localStorage.getItem('email');
    const token = localStorage.getItem('Authorization');
    updateRoles({username:email})

    if (email && token) {
      console.log('Starting with stored email and token');

    

        // First API call to get user role
        AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getallrole`, { email: email })
          .then((res) => {
            console.log(res)
       updateRoles({role: res.data?.cq});
       updateRoles({ooc_user_role: res.data?.ooc});
       updateRoles({userRole: res.data?.retpak});
       const payload = {
        username: email,
        cq:res.data?.cq , // Replace with actual data from API
        ooc:res.data?.ooc,
        retpack: res.data?.retpak,
        isAuthenticate:true,
        email:email
      };
      
        setTimeout(() => {
        console.log(roles);
      }, 3000);
    })
  }
    
  }, [accounts]);

 


  return (

    <div className='row p-0 m-0' style={{ backgroundColor: "black", zIndex: "10000" }}>

      <CommonNavbar
        images={[Logo, INT_Logo]}
        avatar={profilePhoto ? URL.createObjectURL(profilePhoto) : defaultProfilePhoto}
        appVersion={process.env.REACT_APP_VERSION || "Prod 5.1"}
        username={(roles.name)?.length > 0 ? (roles.name) : "Hi, Guest"}
        useremail={(roles.username)?.length > 0 ? (roles.username) : null}

      />

      {/* <CommonHoverCard
        userImg={profilePhoto ? URL.createObjectURL(profilePhoto) : defaultProfilePhoto}
        username={localStorage.getItem('name')?.length > 0 ? localStorage.getItem('name') : "Hi, Guest"}
        useremail={localStorage.getItem('username')?.length > 0 ? localStorage.getItem('username') : null}
      /> */}

      {/* <div className="col-md-1 d-flex justify-content-center align-items-center" style={{ position: 'relative', zIndex: 1000 }}>
        <Menu shadow="md" width={150} opened={opened} onChange={setOpened} offset={0}>
          <Menu.Target>
            <Button onClick={() => togglingDropDown()} variant="filled" color="yellow" size="xs" radius="xs" >
              <IconSettings size={14} color="black" />
            </Button>
          </Menu.Target>

          <Menu.Dropdown>

            <Menu.Item leftSection={<IconHome style={{ width: rem(14), height: rem(14) }} />}>
              <Link to="/" className="dropdown-item text-left">
                Home
              </Link>
            </Menu.Item>

            <Menu.Item leftSection={<IconHelp style={{ width: rem(14), height: rem(14) }} />} >
              <Link to="/contactus" className="dropdown-item text-left">
                Support
              </Link>
            </Menu.Item>

            {
              localStorage.getItem('role') === 'CX_Agent' &&
              <Menu.Item leftSection={<IconUserPlus style={{ width: rem(14), height: rem(14) }} />}>
                <Link to="/register" className="dropdown-item text-left">

                  Register
                </Link>
              </Menu.Item>
            }
            <Menu.Divider />
            <Menu.Item>
              <Button className="dropdown-item text-left m-0 p-0" onClick={handleLogout}>
                <IconLogout style={{ width: rem(14), height: rem(14) }} /> Sign out
              </Button>
            </Menu.Item>

          </Menu.Dropdown>
        </Menu >
      </div> */}
    </div>

  );
};

export default NavBar;
