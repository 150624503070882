import './Navbar.css';
import AxiosApi from '../../Api';
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as ReactBootstrap from 'react-bootstrap';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Container from 'react-bootstrap/Container';
import NavbarComponent from './NavbarComponent.js';
import '././CQ.module.css';
import { Card } from 'react-bootstrap';
import { roles } from '../../Constants.js';

const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;

const NotificationsTable = () => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rate, setrate] = useState(0);
  const columns = [
    {
      dataField: 'acknowledgementNumber',
      text: 'Acknowledgement No.',
      formatter: linkFormatter,
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'complaintNumber',
      text: 'Complaint No.',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'companyName',
      text: 'Company Name',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'notificationMessage',
      text: 'Notifcation Message',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'notificationTime',
      text: 'Notifcation Time',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'email',
      text: 'Email',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    }

  ];
  function linkFormatter(cell, row, rowIndex, extraData) {
    if (cell) {
      if (
        (roles.role) == 'External_Customer' ||
        (roles.role) == 'Internal_Customer'
      ) {
        const url = process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint-2/num/' + cell;
        return <a href={url}>{cell}</a>;
      } else {
        const url = '/complaint/num/' + cell;
        return <a href={url}>{cell}</a>;
      }
    }
    return <span></span>;
  }
  var username, role, userId;
  const rowStyle = { fontWeight: '600' };
  useEffect(() => {
    // GET request using AxiosApi inside useEffect React hook
    username = (roles.username);
    role = (roles.role);
    console.log('Getting data');
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/notifications/cqnotifcationstable')
      .then((response) => {
        console.log(response.data, response, "this is notfications page");
        setPlayers(response?.data);
      })
    // AxiosApi.get(`/fetchUserId?email=${username}`)
    //   .then((response) => {
    //     userId = response?.data?.recordset[0]?.userID;
    //     return AxiosApi.get(`/fetchSoldTo?userId=${userId}`);
    //   })
    //   .then((response) => {
    //     for (var i = 0; i < response?.data.recordset.length; i++) {
    //       AxiosApi.get(
    //         `/database/api/v1/comp/2?email=${username}&role=${role}&soldTo=${response?.data.recordset[i]['parentAccountNumber']}`,
    //       ).then(function (response) {
    //         // handle success
    //         console.log(response?.data);
    //         if (role === 'ABI_CQ_SPOC' || role==='ABI_CQTeam' || role==='Manager') {
    //           setPlayers(response?.data);
    //         } else setPlayers((players) => [...players, ...response?.data]);
    //       });
    //     }
    //   });

    // setLoading(true);
  }, [rate]);

  const options = {
    onSizePerPageChange: (sizePerPage, page) => {
      console.log('Size per page change!!!');
      console.log('Newest size per page:' + sizePerPage);
      console.log('Newest page:' + page);
    },
    onPageChange: (page, sizePerPage) => {
      console.log('Page change!!!');
      console.log('Newest size per page:' + sizePerPage);
      console.log('Newest page:' + page);
    },
  };
  return (
    <div className="AllComplaints background">
      {/* {loading ? ( */}
      <div>
        <NavbarComponent />
        <Container>
          <ToolkitProvider
            keyField="name"
            data={players}
            columns={columns}
            pagination={paginationFactory(options)}
            exportCSV={{ onlyExportFiltered: true, exportAll: false }}
            search
          >
            {(props) => (
              <div className="mar">
                <h3 className="complaints-title">Notifications</h3>
                <hr />
                <div>
                  <SearchBar {...props.searchProps} />
                  <ClearSearchButton {...props.searchProps} />
                  <ExportCSVButton
                    className="export-button noBorder text-right"
                    {...props.csvProps}
                    style={{ textAlign: 'right' }}
                  >
                    Export to CSV
                  </ExportCSVButton>
                </div>
                <div className="table-up noBorder expanded-container">
                  <BootstrapTable
                    className="table-background-2 bootstrap-table-2"
                    {...props.baseProps}
                    pagination={paginationFactory()}
                    filter={filterFactory()}
                    rowStyle={rowStyle}
                    striped
                    hover
                    condensed
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </Container>
        <div className="bottom-fixed"></div>
      </div>
      {/* ) : (
        <ReactBootstrap.Spinner animation="border" /> */}
      {/* )} */}
    </div>
  );
};

export default NotificationsTable;
