const roles = {
    unique_notific: "",
    username: "",
    Authorization: "",
    role: "",
    name: "",
    snowtoken: "",
    emailid: "",
    isAuthenticate: "",
    isReady: "",
    ooc_user_role: "",
    userRole: "",
    JWT: "",
    ztToken: ""  // corrected the duplicate keys
};
 
const updateRoles = (newValues) => {
    for (const key in newValues) {
        if (roles.hasOwnProperty(key)) {
            roles[key] = newValues[key];
        }
    }
};
 
export { roles, updateRoles };